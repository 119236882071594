<template>
    <div class="m-5">
        <div class="row ">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 card-container" v-for="(product, index) in products"
                 v-bind:key="index">
                <div class="card card-pricing border-0 text-center pb-4">
                    <div class="card-header bg-gradient-lighter p-5 clearfix">
                        <img :src="product.img" alt="Image" class=" product-img" height="120" width="120">
                    </div>
                    <div class="card-body text-left mt-3">
                        <h2 class="text-uppercase ">{{ product.name }}</h2>
                        <hr class="m-0">
                        <div class="product-details">
                            <div class="mt-3 float-left">

                                <p class="color-theme"> Rs.
                                    <span style="font-size: medium" class="ml-2 text-through">{{
                                            product.display_price
                                        }}</span>
                                    <span class="font-weight-800 pl-3">{{ product.selling_price }}</span>
                                </p>
                            </div>
                            <div class="mt-3 float-right">
                                <div v-if="product.count>0">
                                    <b-button variant="info"
                                              class="btn btn-sm btn-icon-only rounded-circle m-1"
                                              @click.prevent="addToCart(product, 'minus')">-
                                    </b-button>
                                    <b v-html="product.count"></b>
                                    <b-button variant="info"
                                              class="btn btn-sm btn-icon-only rounded-circle m-1"
                                              @click.prevent="addToCart(product, 'plus')">+
                                    </b-button>
                                    <b-button variant="danger"
                                              class="btn btn-sm btn-icon-only rounded-circle m-1"
                                              @click.prevent="removeCart(product)">
                                        <i class="simple-icon-trash"></i>
                                    </b-button>
                                </div>
                                <div v-else>
                                    <b-button variant="info" title="add to cart"
                                              class="btn btn-sm  br-15" @click="addToCart(product, 'first')">
                                        Add
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="text-center">
            <button id="fixedButton"
                    type="button"
                    class="btn base-button btn-icon-only rounded-circle btn-default btn-xl"
                    @click="vieCart">
                <span class="btn-inner--icon"><i class="ni ni-cart"></i></span>
                <span class="fixed-text">Cart</span>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'ShopCatalog',
    components: {},
    data () {
        return {
            product: '',
            model: {
                id: this.$route.params.id,
                name: '',
                description: '',
                category: '',
                price: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category: {
                    required: true
                },
                price: {
                    required: true
                }
            },
            // need to update //
            products: [],
            data: [],
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: 'name',
                    title: 'Name',
                    sortField: 'name'
                },
                {
                    name: 'description',
                    title: 'Description',
                    sortField: ''
                },
                {
                    name: 'category_id',
                    title: 'Category',
                    sortField: 'category'
                },
                {
                    name: 'selling_price',
                    title: 'Price',
                    sortField: 'price'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },
    beforeMount () {
        this.loadData();
    },

    computed: {
        ...mapGetters(['currentUser'])
    },

    methods: {

        openModal () {
            this.$bvModal.show('detailsModal');
        },
        setData (response) {
            console.log('response =>', response);
            if (response.data.data) {
                this.products = response.data.data;
            }
        },
        async loadData () {
            const response = await axios.get(urls.shopProduct.shopproductlist + '?id=' + this.$route.params.id);
            this.setData(response);
        },

        async addToCart (rowData, action) {
            if (action === 'first') {
                rowData.count = 1;
            } else if (action === 'plus') {
                rowData.count += 1;
            } else {
                rowData.count -= 1;
            }
            const postData = {
                user_id: this.currentUser.id,
                product_id: rowData.id,
                quantity: rowData.count
            };
            const response = await axios.form(urls.cart.addCard, postData);
            if (response.data.error) {
                // this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                // this.setData(response);
            }
            this.openModal();
        },

        async removeCart (rowData) {
            const postData = {
                user_id: this.currentUser.id,
                product_id: rowData.product_master_id
            };
            const response = await axios.form(urls.cart.removeCart, postData);
            console.log(response);
            console.log(response.data);
            if (response.data.error === true) {
                // this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                rowData.count = 0;
                // this.setData(response);
                // this.$bvModal.hide('modal');
            }
        },
        vieCart () {
            const id = this.currentUser.id;
            this.$router.push({ path: '/user/cart/' + id + '/' });
        }
    }
};

</script>
<style scoped>
.btn-info {
    color: #fff;
    background-color: #25a7e1;
    border-color: #25a7e1;
}

#fixedButton {
    position: fixed;
    bottom: 5%;
    right: 2%;
    width: 50px;
    height: 50px;
    font-size: 33px;
    background: #25a7e1;
}

#fixedButton .btn-inner--icon i:not(.fas):not(.fab) {
    position: relative;
    top: 4px;
}

#fixedButton .fixed-text {
    display: none;
    position: absolute;
    right: 35px;
    top: 12px;
    left: -120px;
    background: #25a7e1;
    border-radius: 10px;
    padding: 2px 6px;
    font-size: 13px;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
}

#fixedButton:hover .fixed-text {
    display: block;
    transform: scaleX(1);
    transform-origin: 100% 50%;

}

#fixedButton:focus,
#fixedButton:focus .fixed-text {
    box-shadow: none;
    background: #25a7e1;

}

</style>
